export function TitleHeading(props) {
    return (
        <div className='mx-5 bg-college_red-500 z-90 px-2 my-5 font-bold text-center font-bold text-white text-5xl md:text-4xl'>{props.text}</div>
    )
}

export function MainHeading(props) {
    return (
        <div className='flex bg-college_red-500 px-2 z-90 my-5 font-semibold text-start md:text-center text-white text-3xl md:text-3xl'>{props.text}</div>
    )
}

export function ListHeading(props) {
    return (
        <div className="my-2 mt-5 text-2xl font-semibold text-start md:text-center">

            {props.text}

        </div>
    )
}

export function ListCategory(props) {
    return (
        <div className="mt-4 text-xl md:text-xl font-semibold ">

            {props.text}

        </div>
    )
}

export function ListSubCategory(props) {
    return (
        <div className="my-1 text-xl text-white font-semibold">

            {props.text}

        </div>
    )
}

export function ListItem(props) {
    return (
        <div className="my-1 text-start text-white/90 md:m-1">

            {props.text}

        </div>
    )
}

export function ProgramListItem(props) {
    return (
        <div className="my-1 md:mt-4 text-start  md:text-xl text-white/90">

            {props.text}

        </div>
    )
}



