import fulllogo from './assets/fulllogo-1200.webp'


export default function Header() {
    return (
        <div className='pt-5 pb-2'>
            <div className=' flex w-full justify-center'>
                <div className=' w-3/4'>
                    <img src={fulllogo} alt='PSSC Logo' />
                </div>
            </div>



        </div>
    )
}