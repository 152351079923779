const studentData = [
    {
        'class': '12A', 'students': [
            'Brayden Akuhata',
            'Phoebe Amadio',
            'Lachy Anwyl',
            'Ni Luh Aprisuh',
            'Lucy Barrett',
            'Olivia-Louise Battese',
            'Cooper Beekman',
            'Cilla Beetham-Smith',
            'Tahlia Beltran',
            'Cumorah Berkett',
            'Marinka Bidgood',
            'Jack Bolton',
            'Reese Bowker',
            'Jenna Brown',
            'Cruze Budd',
            'Kyal Butcher',
            'Brandon Campbell',
            'Joe Capone',
            'Aliya Cecil',
            'Chiedza Chirikure',
            'Nikita Clarke',
            'Tyler Collins',
            'Sebastien Corby',
            'Chiara Costantini',
            'Madeline Crabb',
        ]
    },
    {
        'class': '12B', 'students': [
            'Georgia Creighton',
            'Jayden Dalrymple',
            'Brodie Dann',
            'Nungalka Davis Angeles',
            'Ella De Ruiter',
            'Mike Delon',
            'Ben Dickinson',
            'Sophie Doherty',
            'Holly Dowler',
            'Mason Dunlop',
            'Ricardo Edwards',
            'Kaiden Feaver',
            'Sean Flenady',
            'Sam Fortuin',
            'Zion Fray',
            'Katie Freeman',
            'Lionel Gabolinscy',
            'Liani Gardiner',
            'Traie Glancy',
            'Beauden Gouge',
            'Madeline Grima',
            'Jasper Hall',
            'Tamia Hapeta',
            'George Havea',
            'Hayleigh Henderson',
            'Anya Hildyard',
            'Madeline Hooper',
            'Sammy Hunt',
            'Kade Ingham',
            'Hannah Ireland',
            'Jorja Jeffery-Hore',
            'Libby Johnson-Mann',
            'Samuel Jones',
            'Ziyad Karime',
        ]
    },
    {
        'class': '12C', 'students': [
            'Mark Kent',
            'Kiki King',
            'Nalani Kleynhans',
            'Ilyada Kutbay',
            'Alizaye Laban',
            'Jude Lee-Clarke',
            'Felicity Lockwood',
            'Samantha Maguire',
            'Aaliyah Mamoe',
            'Jazmine Manuel',
            'Aliyah Martin',
            'Alizay Mata',
            'Emily McCann',
            'Jayden McKay',
            'JJ McKenzie',
            'Jaxon Milford',
            'Cohwan Morrison',
            'Johnell Moses',
            'Ariki Murphy',
            'Mya Namana',
            'Brodie Neech',
            `Jayke O'Connor`,
            'Daniel Osbourne',
            'Cameron Page',
            'Eli Palalagi-Viliko',
            'John Park',
            'Tahlia Pesu',
            'Rease Pope',
            'Erin Pratt',
            'Kingston Ranginui',
            'Cimarra Reilly',
            'Jake Rigoll',
            'Trinity Robertson',
        ]
    },
    {
        'class': '12D', 'students': [
            'Harrison Ryan',
            'Adilah Santillan',
            'Evan Scrimgeour',
            'Roseleen Singh',
            'Hone Slade-Larsen',
            'Aaliyah Strafford',
            'Francis Taleni',
            'Xander Tarr',
            'Samara Thornton',
            'Bodie Tilse',
            'Zahli Toon',
            'Jelani Treen',
            'Jackson Trinder',
            'Tony Vaughan',
            'Bailey Waide',
            'Ruari Waugh',
            'Eva Weah',
            'Hayden Webber',
            'Devinia Wells',
            'Kiriana Weston',
            'Kodie Weti',
            'Lucy Williams',
            'Bailey Winikerei',
            'Dakota Woelfle',
            'Ciaran Wyatt',
            'Madeline Yong',
            'Precilla Aleng',
            'Kaleb Anderson',
            'Lavesh Appadoo',
            'Kiara Baker-Whitehurst',
            'Dylan Bates',
            'Josh Beaton',
            'Bryce Beetham-SMith',
            'Saga Bell',
            'Darcy Bench',
            'Madison Bickle',
            'Caydin Bobby',
            'Sienna Bosenberg',
            'Wesley Brotherton',
            'Caleb Browne',
            'Jay Burles',
            'Judah Caltibiano',
            'Naomi Campbell',
            'Riley Caswell',
            'Emelina Chandler',
            'Kaly Clarke',
            'Gabriel Clemente',
            'Olivia Cooper',
            'Andrea Cornelius',
            'Cheyenne Covus',
            'Aneika Craft',
            'Ty Croke',
            'Bailey Danielson',
            'Aaron Dark',
            'Laura de Jersey',
            'Maximus Delander',
            'Makayla Diacono',
            'Samuel Dobbs',
            'Jordan Doughty',
            'Flynn Drady',
            'Samuel Ebens',
            'Madyson Falconer',
            'Ethan Flemming',
            'Keely Folbigg',
            'Hayley Franklin',
            'Izaac Frazer',
            'Tarkyn Frost',
            'Grant Galvez',
            'Ethan Gibson',
            'Olivia Goodlock',
        ]
    },
    {
        'class': '12E', 'students': [
            'Orlando Grace',
            'Emily Gunter',
            'Hayley Ham',
            'Jake Harrison',
            'Malakylin Hayward',
            'Bailey Higgins',
            'Tristan Hitler-Brookes',
            'Mark Hudson',
            'Harvey Huth',
            'Seanna Intal',
            'Jared Jacobs',
            'Jacinta Jessop',
            'Declan Jones',
            'Myra Kaikura-Tapu',
            'Charlize Kaulfers',
            'Ebony Kickbusch',
            'Koda Kirkus',
            'Lachan Kovarik',
            'Aeisha Laban',
            'Jahaleia Lammon',
            'Cohen Littlejohn',
            'Deuter Macalos',
            'Julius Maiava',
            'Addison Mandavy',
            'Diaje Marsters-Faavesi',
            'Kaylem Martin',
            'Juanita Matenga',
            'Megan McGann',
            'Riley McKay',
            'Georgia Meredith',
            'Chloe Moon',
            'Charlotte Morse-Tamanui',
            'Mohammed Mousselmani',
            'Saxon Murray',
            'Bree-Anna Neal',
            'Gracie Nuspan',
            'Uthman Oriaje',
            'Kyson Otto',
            'Jobi Palalagi',
            'Hailie Pallamountagne',
            'Diya Patel',
            'Logan Piggott',
        ]
    },
    {
        'class': '12F', 'students': [
            'Khlisha Porter',
            'Mark Randall',
            'Willow Rayfield',
            'Tristan Richardson',
            'Tait Robertson',
            'Bridie Robinson',
            'Brayden Sajnovic',
            'Jake Saville',
            'Alyssa Sice',
            'Kalani Sione',
            'Daniel Smith',
            'Tianna Struhs',
            'Corbyn Tamatea-Nelley',
            'Isaac Thompson',
            'Zachary Tiatto',
            'Anabelle Tolley-Wilks',
            'Francine Torres',
            'Aleah Trindall',
            'Ashlee Truloff',
            'Medha Wadhwa',
            'Rylei Ware',
            'Taiysh Waugh',
            'Rory Webb',
            'Christian Webber-Lai',
            'Ana-Tia Weston',
            'Kahnye Wetere',
            'Camichael William',
            'Lily-Jai Wilson',
            'Mitchell Winter',
            'Tiana Wolstenholme',
            'Aydin Yigit',
            'Savannah Zarillo',
        ]
    },





];

const programData = [

    { "category": "Introduction", "participants": ["Ms Pettina Dargusch"] },
    { "category": "Acknowledgement of Country", "participants": ["Georgia Padroth"] },
    { "category": "National Anthem", "participants": ["Emily"] },
    { "category": "Welcome", "participants": ["Jesse-Jaymes", "Emily"] },


    { "category": "Acknowledgement of Achievement", "participants": ["Ms Angela Ormonde"] },
    { "category": "Principal's Address", "participants": ["Ms Megan Roderick"] },
    { "category": "P&C President's Address", "participants": ["Ms Sara Syme"] },
    { "category": "Introduction of Graduates", "subheading": "First Half", "participants": ["Ms Megan Roderick", "Mr Reid Bolus", "Ms Pettina Dargusch"] },
    { "category": "Video", "participants": ["Trey-Tomairangi Pohe"] },
    { "category": "Introduction of Graduates", "subheading": "Second Half", "participants": ["Ms Megan Roderick", "Mr Reid Bolus", "Ms Pettina Dargusch"] },

    { "category": "Captain's Speeches", "participants": ["Rachna", "Hepuruna"] },
    { "category": "Final Thanks and Farewell", "participants": ["Mr Reid Bolus"] },

    { "category": "Video", "participants": [] },

    { "category": "Farewell", "participants": ["Jesse"] },


];

export { studentData, programData }