import './App.css';

import { studentData, programData } from './data.js'

import student_data from './results.json'

import Footer from './Footer';
import Header from './Header';

import { MainHeading, TitleHeading, ListSubCategory, ListCategory, ListItem, ListHeading, ProgramListItem } from './Headings';




function ProgramList() {
  return (

    <div className='mx-5 grid grid-cols-1'>
      {Object.keys(programData).map((key, index) => {
        return (
          <div className='flex flex-col text-white text-xl md:flex-row' key={index}>

            <div className='w-full md:w-1/2 md:text-end'>
              <ListCategory text={programData[index].category} />
              {programData[index].subheading != null ? <ListSubCategory text={programData[index].subheading} /> : <div></div>}
            </div>
            <div className='w-5' />
            <div className='w-full md:w-1/2 flex flex-col md:text-start'>
              {programData[index].participants.map(item => {
                return (
                  <ProgramListItem text={item} />
                )
              })}
            </div>
          </div>

        )
      })}
    </div>
  )
}

function StudentList() {
  return (
    <div className='mx-5 align-start content-start justify-start items-start'>
      <div className='flex flex-col'>
        {student_data.map((item, index) => {
          return (
            <div className='text-center text-white text-xl' key={index}>
              <ListHeading text={item.grade} />
              <div className='grid grid-cols-1 md:grid-cols-3'>
                {item.students.map((student, index) => {
                  return (
                    <ListItem key={index} text={student.firstName + ' ' + student.lastName} />
                  )
                })}

              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function App() {



  return (
    <div className="bg-pride_dark_blue-500 min-h-screen bg-repeat bg-center bg-auto bg-fixed bg-[url('./lions.webp')]">

      <div className='flex flex-col bg-pride_dark_blue-500/[0.9] content-center justify-center items-center'>
        <div className=' backdrop-blur-md flex flex-col w-11/12 md:w-1/2 '>



          <Header />



          <div className='w-full flex justify-center relative'>


            {/* <div className='z-0 top-3 bg-college_red-500 w-[16rem] h-[10rem] md:w-[28rem] md:h-14 gpu-transform absolute' /> */}
            <TitleHeading text=' Year 12 Graduation 2023 ' />

            {/* </div> */}

          </div>


          {/* <TitleHeading text='Year 12 Graduation 2023' /> */}
          {/* <div className='bg-fixed bg-red-500 w-32 h-32 z-50' /> */}

          <div className='w-full flex justify-center relative'>
            {/* <div className='z-0 top-4 bg-college_red-500 w-[12rem] h-11 gpu-transform absolute' /> */}
            <MainHeading text=' Program ' />

          </div>

          <ProgramList id='program' />
          <div className='mt-4' />

          <div className='w-full flex justify-center relative'>
            {/* <div className='z-0 top-4 bg-college_red-500 w-[16rem] h-11 gpu-transform absolute' /> */}
            <MainHeading text=' Class of 2023 ' />

          </div>

          <StudentList />















          <Footer />

        </div>



      </div>






    </div>
  );
}

export default App;
