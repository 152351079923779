import facebook from './assets/facebook.webp'
import youtube from './assets/youtube.webp'
import instagram from './assets/instagram.webp'


function SocialsBar() {
    return (
        <div className='flex flex-row my-5 justify-around md:justify-end md:content-end md:items-end md:align-end space-x-10 md:space-x-5'>
            <a href='https://pimpamassc.eq.edu.au/' target='__blank' aria-label='PSSC Website'>
                <div className='h-6 w-6'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                    </svg>
                </div>
            </a>
            <a href='mailto:admin@pimpamassc.eq.edu.au' target='__blank' aria-label="PSSC Email">
                <div className='h-6 w-6'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                </div>
            </a>
            <a href='https://www.facebook.com/PimpamaStateSecondaryCollege' target='__blank' aria-label="Facebook">
                <div className='h-6 w-6'>
                    <img src={facebook} alt='Facebook' />
                </div>
            </a>
            <a href='https://www.youtube.com/user/pimpamassc' target='__blank' aria-label="YouTube">
                <div className='h-6 w-6'>
                    <img src={youtube} alt='YouTube' />
                </div>
            </a>
            <a href='https://www.instagram.com/pimpamassc/' target='__blank' aria-label="Instagram">
                <div className='h-6 w-6'>
                    <img src={instagram} alt='Instagram' />
                </div>
            </a>




        </div>
    )
}

function ContactInfo() {
    return (
        <div className='text-white flex flex-col justify-between space-y-2 my-5'>
            <div className='flex flex-row space-x-2'><div className='font-medium'>A</div><div>Dixon Drive, Pimpama, QLD, 4209</div></div>
            <div className='flex flex-row space-x-2'><div className='font-medium'>E</div><div>admin@pimpamassc.eq.edu.au</div></div>
            <div className='flex flex-row space-x-2'><div className='font-medium'>P</div><div>07 5540 9333</div></div>
            {/* <div className='flex flex-row space-x-2'><div className='font-medium'>F</div><div>07 5540 7270</div></div> */}
        </div>

    )
}

function FooterHeader() {
    return (

        <div className='text-white flex flex-col justify-between'>
            <div className='text-3xl'>Pimpama State Secondary College</div>
            <div className='text-xl'>Together We Succeed</div>
        </div>

    )
}

export default function Footer() {
    return (
        <div className='w-full '>
            <div className='m-5 mt-7'>

                <FooterHeader />
                <div className='flex flex-col md:flex-row md:content-between justify-between'>
                    <ContactInfo />
                    <SocialsBar />

                </div>
            </div>

        </div>
    )

}